<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Noviembre 2023                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- filtros de busqueda y botón para agregar un relación -->
        <div class="d-flex pt-3">
            <v-text-field class="me-2" outlined dense hide-details label="NIT" v-model="buscadorNitAseguradora"
                @paste="handlePaste($event, 'buscadorNitAseguradora')" @keypress="validarLetra" @keyup="listarRelaciones()">
            </v-text-field>
            
            <v-text-field class="filtros me-2" outlined dense hide-details label="Aseguradora"
                v-model="buscadorNombreAseguradora" @keyup="listarRelaciones()">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Código habilitación" v-model="buscadorCodigoHabilitacion" 
                @paste="handlePaste($event, 'buscadorCodigoHabilitacion')" @keypress="validarLetra" @keyup="listarRelaciones()">
            </v-text-field>

            <v-text-field class="filtros" outlined dense hide-details label="Prestador"
                v-model="buscadorNombrePrestador" @keyup="listarRelaciones()">
            </v-text-field>

            <v-tooltip left color="success">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on"
                        @mousedown.prevent="dialog = true">
                        <v-icon> add </v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>
        </div>

        <!-- dialogo para crear una relación -->
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="45.7rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Crear relación</span>
                </v-card-title>

                <!-- formulario crear una relación -->
                <v-card-text class="pa-5">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form ref="nuevaRelacion" class="mt-3">

                            <!-- Campo para buscar y elegir una aseguradora -->
                            <div>
                                <validation-provider ref="aseguradoras" v-slot="{ errors }" :rules="{ required: true, aseguradoraActiva: true }" style="max-width: 100%;">
                                    <v-autocomplete v-model="nuevaRelacion.aseguradora" label="Aseguradora" dense outlined :error-messages="errors" 
                                        :items="listaDeAseguradoras" :menu-props="{ maxHeight: 161, offsetY: true, maxWidth: 691 }"
                                        no-data-text="No se encontraron resultados.">
                                    </v-autocomplete>
                                </validation-provider>
                            </div>

                            <!-- Campo para buscar y elegir un prestador -->
                            <div>
                                <validation-provider ref="prestadores" v-slot="{ errors }" :rules="{ required: true, prestadorActivo: true }" style="max-width: 100%;">
                                    <v-autocomplete v-model="nuevaRelacion.prestador" label="Prestador" dense outlined :error-messages="datosExistentesError || errors" 
                                        :items="listaDePrestadores" :menu-props="{ maxHeight: 161, offsetY: true, maxWidth: 691 }"
                                        no-data-text="No se encontraron resultados.">
                                    </v-autocomplete>
                                </validation-provider>
                            </div>
                            
                            <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para relación-->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="reset()">
                                    CERRAR
                                </v-btn>
                                <v-btn depressed :disabled="botonDeshabilitado || invalid" color="success" text @click="guardarRelacion()">
                                    GUARDAR
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra los registros de la relación aseguradora-prestador-->
        <v-data-table :loading="loading" fixed-header :headers="headersRelaciones" :items="listaDeRelaciones"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
            class="elevation mt-4" height="66vh" :footer-props="footerPropsTabla">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="(item) in items" :key="item.index">
                        <td>{{ item.nit }}</td>
                        <td>{{ item.nombreAseguradora }} - {{ item.detalleClienteAseguradora }}</td>
                        <td>{{ item.codigoHabilitacion }}</td>
                        <td>{{ item.nombrePrestador }} - {{ item.detalleClientePrestador }}</td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'"> 
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-tooltip bottom :color="(item.estadoAseguradora === true || item.estadoPrestador === true) ? 'grey' : (item.eliminado === false ? 'error' : 'success')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @mousedown.prevent="item.estadoAseguradora !== true && item.estadoPrestador !== true ? abrirDialogoEstado(item) : null">
                                        <v-icon :color="(item.estadoAseguradora === true || item.estadoPrestador === true) ? 'grey' : (item.eliminado === false ? 'error' : 'success')">
                                            {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="item.estadoAseguradora === true || item.estadoPrestador === true">Aseguradora y/o<br>prestador inactivo</span>
                                <span v-else>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="22.7rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ mensajeEstado }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed text :disabled="desactivar" @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import * as rules from 'vee-validate/dist/rules';
import { required } from 'vee-validate/dist/rules';

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});

extend('required', {
    ...required,
    message: 'Este campo es requerido',
});

let estadoPrestador = null;
extend('prestadorActivo', {
    validate: (value) => {
        if (value && estadoPrestador === true) {
            return false;
        }
        return true; 
    },
    message: 'El prestador está inactivo',
});

let estadoAseguradora = null;
extend('aseguradoraActiva', {
    validate: (value) => {
        if (value && estadoAseguradora === true) {
            return false;
        }
        return true; 
    },
    message: 'La aseguradora está inactiva',
});

export default {

    name: "AseguradoraPrestador",
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            buscadorNitAseguradora: '',
            buscadorNombreAseguradora: '',
            buscadorCodigoHabilitacion: '',
            buscadorNombrePrestador: '',
            dialog: false,
            buscarTextoErrorAseguradora: '',
            mostrarListaAseguradora: false,
            buscarTextoErrorPrestador: '',
            mostrarListaPrestador: false,
            nuevaRelacion:{
                aseguradora: '',
                prestador: '',
            },
            eliminado: false,
            estado: 'A',
            usuario: '',
            userRoles: {},
            roles: Role,
            listaDeRelaciones: [],
            pagina: 1,
            totalPaginas: 0,
            itemsPorPagina: 10,
            loading: false,
            headersRelaciones: [
                { text: "NIT", width: "10%", sortable: false, },
                { text: "ASEGURADORA", width: "30%", sortable: false, },
                { text: "CÓDIGO HABILITACIÓN", align: "left", width: "10%", sortable: false, },
                { text: "PRESTADOR", width: "30%", sortable: false, },
                { text: "ESTADO", width: "10%", sortable: false, },
                { text: "ACCIONES", align: "center", width: "10%", sortable: false, }
            ],
            footerPropsTabla: {
                'items-per-page-options': [10, 15, 20, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
            dialogoCambiarEstado: false,
            mensajeEstado: '',
            relacionSelecionada: {},
            datosExistentesError: '',
            validacionExitosa: false,
            detalleCliente: null,
            codigoHabilitacion: '',
            listaDeAseguradoras: [],
            listaDePrestadores: [],
            estadoPrestador: null,
            estadoAseguradora: null,
            desactivar: false,
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.userRoles = this.auth.roles;
        this.listarRelaciones();
    },

    // Se mapean los estados del store a propiedades computadas
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
        botonDeshabilitado() {
            return this.nuevaRelacion.prestador === '' || this.nuevaRelacion.aseguradora === '' || this.datosExistentesError !== '' || this.validacionExitosa || this.desactivar;
        }
    },

    // Se vigila el cambio en las variables y se ejecuta listarRelaciones()
    watch: {
        pagina: function () {
            this.listarRelaciones();
        },
        itemsPorPagina: function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorNitAseguradora': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorCodigoHabilitacion': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorNombrePrestador': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorNombreAseguradora': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'dialog': function () {
            if (this.dialog === true) {
                this.listaPrestadoresPorEmpresa();
                this.listaAseguradorasPorEmpresa(); 
            }
        },
        'nuevaRelacion.prestador': function () {
            const prestador = this.listaDePrestadores.find(p => p.value === this.nuevaRelacion.prestador);
            estadoPrestador = prestador ? prestador.estado === true : false;
            this.validarExistencia();
        },
        'nuevaRelacion.aseguradora': function () {
            const aseguradora = this.listaDeAseguradoras.find(p => p.value === this.nuevaRelacion.aseguradora);
            estadoAseguradora = aseguradora ? aseguradora.estado === true : false;
            this.validarExistencia();
        },
        'dialogoCambiarEstado': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.desactivar = false;
                }, 500)
            }
        }
    },

    // Se asigna el valor del username del usuario autenticado a usuario en mayúsculas
    mounted() {
        this.usuario = this.auth.username.toUpperCase();
        this.listaPrestadoresPorEmpresa();
        this.listaAseguradorasPorEmpresa();
    },

    methods: {
        // Se mapean las mutaciones del store a métodos locales
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),

        /**
         * Obtiene la lista de prestadores asociados a la empresa actual.
         * La lista se obtiene haciendo una solicitud HTTP al servidor y mapeando los datos de respuesta.
         * Cada prestador se representa como un objeto con propiedades 'text', 'value' y 'estado'.
         * 'text' representa la presentación del prestador en la interfaz de usuario.
         * 'value' representa el identificador único del prestador.
         * 'estado' indica si el prestador está marcado como eliminado en la base de datos.
         * En caso de error durante la solicitud, se registra un mensaje de error en la consola.
         */
        async listaPrestadoresPorEmpresa() {
            try {
                const response = await this.$http.get(`msa-administration/prestador/listEmpresa`, {
                    params: {
                        idEmpresa: this.enterprise.code
                    },
                });
                this.listaDePrestadores = response.data.map(item => ({
                    text: `${item.codigoHabilitacion} - ${item.nombrePrestador} - ${item.detalleCliente}`,
                    value: item.idPrestador,
                    estado: item.eliminado,
                    codigo: item.codigoHabilitacion,
                }));
                
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Obtiene la lista de aseguradoras asociadas a la empresa actual.
         * La lista se obtiene haciendo una solicitud HTTP al servidor y mapeando los datos de respuesta.
         * Cada aseguradora se representa como un objeto con propiedades 'text', 'value' y 'estado'.
         * 'text' representa la presentación de la aseguradora en la interfaz de usuario.
         * 'value' representa el identificador único de la aseguradora.
         * 'estado' indica si la aseguradora está marcada como eliminada en la base de datos.
         * En caso de error durante la solicitud, se registra un mensaje de error en la consola.
         */
        async listaAseguradorasPorEmpresa() {
            try {
                const response = await this.$http.get(`msa-administration/aseguradora/listEmpresa`, {
                    params: {
                        idEmpresa: this.enterprise.code
                    },
                });
                this.listaDeAseguradoras = response.data.map(item => ({
                    text: `${item.nit} - ${item.nombreAseguradora} - ${item.detalleCliente}`,
                    value: item.idAseguradora,
                    estado: item.eliminado,
                }));
            } catch (error) {
                console.log(error);
            }
        },

        /**
         * Maneja el evento de pegado en un campo de entrada, permitiendo solo valores numéricos.
         * @param {Event} event El evento de pegado.
         * @param {string} fieldName El nombre del campo de entrada donde se pegará el contenido.
         */
        handlePaste(event, fieldName) {
            event.preventDefault();
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('text');
            if (!/^\d+$/.test(pastedData)) {
                return;
            }
            this[fieldName] = pastedData;
        },

        /**
         * Función para validar la entrada de caracteres en un evento de tecla.
         * Se encarga de validar que solo se ingresen ciertos caracteres permitidos.
         * @param {Event} event - Evento de tecla
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },

        /**
         * Obtiene la lista de relaciones mediante una solicitud HTTP GET al servidor.
         * Las relaciones se obtienen con paginación y se filtran según los parámetros especificados.
         * Se actualiza el número total de páginas.
         * Además, se controla el estado de carga durante la solicitud.
         */
        listarRelaciones() {
            this.loading = true;
            this.$http
                .get(`msa-administration/aseguradoraPrestador/listar`, {
                    params: {
                        page: this.pagina - 1,
                        size: this.itemsPorPagina,
                        idEmpresa: this.enterprise.code,
                        nit: `${this.buscadorNitAseguradora.toLowerCase()}`,
                        nombreAseguradora: `${this.buscadorNombreAseguradora.toLowerCase()}`,
                        codigoHabilitacion: `${this.buscadorCodigoHabilitacion.toLowerCase()}`,
                        nombrePrestador: `${this.buscadorNombrePrestador.toLowerCase()}`,
                    },
                })
                .then(response => {
                    this.listaDeRelaciones = response.data.content;
                    // Actualizar el número total de páginas
                    this.totalPaginas = response.data.totalElements;
                    this.loading = false;
                }).catch(error => {
                    console.log(error)
                    this.loading = false;
                })
        },

        /**
         * Se encarga de realizar una validación para verificar si existe una relación entre el id de la aseguradora 
         * y el id del prestador seleccionados en la tabla aseguradora_prestador de postgres.
         */
        validarExistencia() {
            if (this.nuevaRelacion.aseguradora && this.nuevaRelacion.prestador) {
                this.$http
                    .get("msa-administration/aseguradoraPrestador/existencia", {
                        params: {
                            idAseguradora: this.nuevaRelacion.aseguradora,
                            idPrestador: this.nuevaRelacion.prestador
                        }
                    })
                    .then((response) => {
                        if (response.data !== "La relación no existe") {
                            this.datosExistentesError = "La relación ya existe.";
                            this.validacionExitosa = true; // La validación no fue exitosa
                        } else {
                            this.datosExistentesError = "";
                            this.validacionExitosa = false; // La validación fue exitosa
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.datosExistentesError = "Error en la validación de datos";
                        this.validacionExitosa = true; // La validación no fue exitosa
                    });
            } else {
                this.datosExistentesError = "";
            }
        },

        /**
         * Guarda la información de una nueva relación en el servidor.
         * Crea un objeto con los datos de la relación, incluyendo la fecha de creación actual,
         * y realiza una solicitud al servidor para ingresar la relación. Luego, actualiza la lista
         * de las relaciones y restablece el formulario.
         */
        guardarRelacion() {
            this.listaPrestadoresPorEmpresa();
            this.desactivar = true;
            let prestadorSelecionado = this.listaDePrestadores.find(prestador => prestador.value === this.nuevaRelacion.prestador);
            if (typeof prestadorSelecionado === 'object') {
                this.codigoHabilitacion = prestadorSelecionado.codigo;
            } else {
                this.codigoHabilitacion = '';
            }
            // Realiza la petición al backend para guardar la relación
            const relacionarProducto = {
                idCopr: null,
                idAseguradora: this.nuevaRelacion.aseguradora,
                idEmpresa: this.enterprise.code,
                idPrestador: this.nuevaRelacion.prestador,
                codigoHabilitacion: this.codigoHabilitacion,
                eliminado: this.eliminado,
                usuario: this.usuario,
            };
            // Realizar la petición POST al controlador para guardar la relación
            this.$http
                .post(`msa-administration/aseguradoraPrestador/crear`, relacionarProducto)
                .then(() => {
                    this.listarRelaciones();
                    this.reset();
                })
                .catch(error => {
                    console.error(error);
                });
        },
        
       /**
         * Abre el diálogo para cambiar el estado de una relación.
         * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
         * Guarda la relación seleccionada en la propiedad 'relacionSelecionada'.
         * Este método se invoca cuando se desea cambiar el estado de una relación.
         * @param {object} item - El relación seleccionada.
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true;
            this.relacionSelecionada = item;
            this.mensajeEstado = this.relacionSelecionada.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
        },

        /**
         * Cambia el estado de una relación.
         * Actualiza el estado de la relación seleccionada ('relacionSelecionada') según la lógica definida.
         * Realiza una solicitud HTTP PUT al servidor para actualizar el estado de la relación.
         * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de relaciones.
         */
        cambiarEstado() {
            this.desactivar = true;
            this.relacionSelecionada.eliminado = (this.relacionSelecionada.eliminado == false) ? true : false;
            const cambiarEstado = {
                idAspr: this.relacionSelecionada.idAspr,
                idAseguradora: this.relacionSelecionada.idAseguradora,
                idEmpresa: this.enterprise.code,
                idPrestador: this.relacionSelecionada.idPrestador,
                codigoHabilitacion: this.relacionSelecionada.codigoHabilitacion,
                eliminado: this.relacionSelecionada.eliminado,
                usuario: this.usuario,
            };
            this.$http
                .put(`msa-administration/aseguradoraPrestador/cambiarEstado`, cambiarEstado)
                .then(() => {
                    this.dialogoCambiarEstado = false;
                    this.listarRelaciones();
                }).catch(error => {
                    console.log(error);
                })
        },

        /**
         * Restablece los valores de los campos y las variables relacionadas a la creación de una relación.
         * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
         * Reinicia el estado de edición y activación de campos.
         * Reinicia el estado del componente de observación (refs.observer).
         */
        reset() {
            this.dialog = false;
            this.buscarTextoErrorAseguradora = '';
            this.mostrarListaAseguradora = false,
            this.buscarTextoErrorPrestador = '';
            this.mostrarListaPrestador = false,
            this.nuevaRelacion.aseguradora = '';
            this.nuevaRelacion.prestador = '';
            this.datosExistentesError = '';
            this.dialogoCambiarEstado = false;
            this.validacionExitosa = false;
            this.$refs.observer.reset();
            this.desactivar = false;
        },
    },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}

::v-deep .elevation .v-data-footer {
    width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
    margin: 5px;
    margin-left: 10px;
}

::v-deep .elevation .v-data-table__wrapper {
    border: 1px solid #f7f6f6;
}

.fondoDialog {
    background-color: #1867c0;
    color: rgb(223, 223, 223) !important;
}

.mensaje {
    text-align: center;
    padding-top: 120px !important;
}

.mensaje em {
    font-size: larger;
    color: rgb(161 161 162);
}

.mensaje:hover {
    background-color: white;
}

.icono {
    font-size: 50px;
}

::v-deep .v-tabs .v-tabs-slider-wrapper {
    top: 80% !important;
}

::v-deep .tablaRelaciones div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

.header tr {
    background-color: rgb(223, 223, 223) !important;
}

.header tr th {
    font-weight: bold;
    color: black !important;
}

.archivo {
    outline: 2px dashed grey;
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 1rem;
    height: 100px;
    position: relative;
    cursor: pointer;
}

.archivo p {
    margin-top: .7rem;
    text-align: center;
}

.seleccionarArchivo {
    opacity: 0;
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
}

.error-message {
    color: red;
}

.rotate-animation {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.resultadoLista {
    max-height: 12rem;
    overflow-y: auto;
    z-index: 999;
    width: 100%;
}
</style>